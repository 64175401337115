<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { Button } from '@/components/ui/button';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { getParam } from '@/lib/utils';
import { toast } from '@/components/ui/toast/use-toast';
import { Loader2 } from 'lucide-vue-next';
import { usePublisher } from '@/utils/publisher';

interface CheckoutParams {
    appID: string | string[];
    successBackUrl: string;
    errorBackUrl: string;
}

interface CheckoutResponse {
    status: string;
    data?: {
        url: string;
    };
    msg?: string;
}

const route = useRoute();
const appID = computed(() => route.query.appID);
const loader = ref(false);
const publisher = usePublisher();

async function pay() {
    if (loader.value) {
        return;
    }

    if (!appID.value) {
        handleError('Invalid appointment ID.');
        return;
    }

    try {
        loader.value = true;
        const params: CheckoutParams = {
            appID: appID.value.toString(),
            successBackUrl: `${window.location.href.split('/failed_pay')[0]}/success`,
            errorBackUrl: window.location.href.split('?')[0]
        };

        const response = await axios.post('/checkout', params) as CheckoutResponse;

        if (response.status === 'success' && response.data?.url) {
            window.location.href = response.data.url;
        } else {
            handleError(response.msg || 'Payment failed. Please try again.');
        }
    } catch (error) {
        handleError('Payment failed. Please try again.');
    }
}

function handleError(message: string) {
    loader.value = false;
    toast({
        title: message,
        variant: 'destructive'
    });
}

onMounted(() => {
    publisher.publish('avee:appointment:failure', {
        error: 'payment-failure'
    });
});
</script>

<template>
    <div class="text-center my-auto">
        <i class="isax isax-close-circle5 text-[60px] text-rose-600 rounded-full"></i>
        <h1 class="text-gray-900 font-bold text-[2rem] mt-3">
            Payment Failed.<br>Let's try again.
        </h1>
        <Button 
            size="lg" 
            class="mt-[60px] w-full text-lg font-bold" 
            @click="pay"
            :disabled="loader"
        >
            <Loader2 v-if="loader" class="size-4 mr-2 animate-spin" />
            Retry Payment
            <i class="isax isax-arrow-right-1 ml-1 text-2xl"></i>
        </Button>
        <Button 
            size="lg" 
            class="mt-3 border border-gray-300 w-full text-lg text-gray-700" 
            variant="ghost" 
            as-child
        >
            <RouterLink 
                :to="{
                    name: 'manage_appointment', 
                    query: { appID }, 
                    params: { domain: getParam(route) }
                }"
            >
                Manage appointment
            </RouterLink>
        </Button>
    </div>
</template>
