import { RouteRecordRaw } from 'vue-router';

import Add from '@/pages/Add.vue';
import Address from '@/pages/Address.vue';
import Book from '@/pages/Book.vue';
import Delegate from '@/pages/Delegate.vue';
import Details from '@/pages/Details.vue';
import Doctors from '@/pages/Doctors.vue';
import FailedPay from '@/pages/FailedPay.vue';
import Login from '@/pages/Login.vue';
import ManageAppointment from '@/pages/ManageAppointment.vue';
import NotFound from '@/pages/NotFound.vue';
import Pay from '@/pages/Pay.vue';
import Pharmacies from '@/pages/Pharmacies.vue';
import Pharmacy from '@/pages/Pharmacy.vue';
import Phone from '@/pages/Phone.vue';
import Success from '@/pages/Success.vue';
import Times from '@/pages/Times.vue';
import Verify from '@/pages/Verify.vue';
import Who from '@/pages/Who.vue';

const routes: RouteRecordRaw[] = [
    { path: '/pharmacy', name: 'pharmacy', component: Pharmacy, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?', name: 'home', component: Book, meta: { requiresAuth: false } },
    { path: '/:domain([\\w-]+)?/phone', name: 'phone', component: Phone, meta: { backPath: 'home', requiresAuth: false } },
    { path: '/:domain([\\w-]+)?/verify', name: 'verify', component: Verify, meta: { backPath: 'phone', requiresAuth: false } },
    { path: '/:domain([\\w-]+)?/login', name: 'login', component: Login, meta: { requiresAuth: false } },
    { path: '/:domain([\\w-]+)?/address', name: 'address', component: Address, meta: { backPath: 'login', requiresAuth: false } },
    { path: '/:domain([\\w-]+)?/who', name: 'who', component: Who, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/add', name: 'add', component: Add, meta: { backPath: 'who', requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/details', name: 'details', component: Details, meta: { backPath: 'who', requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/doctors', name: 'doctors', component: Doctors, meta: { backPath: 'details', requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/times', name: 'times', component: Times, meta: { backPath: 'doctors', requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/pharmacies', name: 'pharmacies', component: Pharmacies, meta: { backPath: 'times', requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/pay', name: 'pay', component: Pay, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/success', name: 'success', component: Success, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/failed_pay', name: 'failed_pay', component: FailedPay, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/manage_appointment', name: 'manage_appointment', component: ManageAppointment, meta: { requiresAuth: true } },
    { path: '/:domain([\\w-]+)?/delegate/:token', name: 'delegate', component: Delegate, meta: { requiresAuth: false } },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: { requiresAuth: false } }
]

export default routes;
