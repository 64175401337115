<script setup lang="ts">
import { getParam } from '@/lib/utils';
import { store } from '@/store/store';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Loader2 } from 'lucide-vue-next';
import { usePublisher } from '@/utils/publisher';

const loader = ref(true);
const router = useRouter();
const route = useRoute();
const publisher = usePublisher();

onMounted(async () => {
  const response = await axios.post('/loginUsingInterimToken', {
    token: route.params.token
  }) as any;

  loader.value = false;

  const redirectToLogin = () => {
    setTimeout(() => {
      loader.value = false;
      router.push({ name: 'login', params: { domain: getParam(route) } });
    }, 300);
  }

  if (response?.status !== 'success') {
    publisher.publish('avee:delegation:failure', {
      error: 'invalid-token'
    });

    redirectToLogin();
    return;
  }

  const token = response.data.token;
  const targetPid = response.data.pid;

  localStorage.setItem('token', token);
  axios.defaults.headers['authorization'] = 'Bearer ' + token;

  const patients = await axios.post('/getPatients') as any;
  
  if (patients.data.length) {
    store.members = patients.data;
    const selectedPatient = patients.data.find((patient: any) => patient.pid === targetPid);
    store.setSelectedPatient(selectedPatient);
    store.appointment.pid = selectedPatient.pid;
    store.login();

    const parts: string[] = [];

    const domain = getParam(route);
    parts.push(domain ? `/${domain}` : '');

    if (typeof route.query['redirect_to'] === 'string') {
      parts.push(route.query['redirect_to'] ?? '');
    }

    const redirectUrl = parts.join('');

    setTimeout(() => {
      loader.value = false;

      if (!!redirectUrl) {
        router.push(redirectUrl);
      } else {
        router.push({ name: 'details', params: { domain: getParam(route) } });
      }
    }, 300);
  } else {
    store.link = 'login';
    redirectToLogin();
  }
})
</script>

<script lang="ts">
export default {
    name: 'Delegate'
}
</script>

<template>
  <div v-if="loader" class="mt-16">
    <div class="mt-3 text-primary text-center">
      <Loader2 class="w-14 h-14 mx-auto animate-spin" />
    </div>
  </div>
</template>
