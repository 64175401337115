<script setup lang="ts">
import { store } from '@/store/store'
import { useRoute } from 'vue-router'
import { ref, onActivated, watch } from 'vue'
import { Button } from '@/components/ui/button'
import axios from 'axios'
import { useToast } from '@/components/ui/toast/use-toast'
import { Hourglass, Loader2, Calendar, User, AlertCircle, XCircle, CheckCircle2, CalendarX2 } from 'lucide-vue-next';
import { getParam, bucketTime, convertServerDateToLocalDate } from '@/lib/utils'
import SupportChat from '@/components/shared/SupportChat.vue';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { usePublisher } from '@/utils/publisher'

const { toast } = useToast()

const route = useRoute()
const title = ref('')
const subtitle = ref('')
const data = ref({} as any);
const d1 = ref('');
const d2 = ref('');
const d3 = ref('');
const loader = ref(true);
const later = ref('');
const id = ref(0);
const cancelLoader = ref(false);
const payLoader = ref(false);
const publisher = usePublisher();

onActivated(() => {

    if (store.app.appID) {
        data.value = store.app;
        if (!data.value.msg || !data.value.time) {
            getAppt();
        } else {
            subtitle.value = data.value.msg;
            setInfo(data.value.time);
        }
    } else if (store.selectedPatient.pid) {
        getAppt();
    }

});

watch(() => store.members, function (val) {

    if (val.length && !store.app.appID && route.name == 'manage_appointment') {

        getAppt();

    }

});

async function getAppt() {
    try {
        const res = await axios.post('/getAppointment', { appID: route.query.appID }) as any;
        if (res.status == 'success') {
            const safeAppData = JSON.parse(JSON.stringify(res.data));
            data.value = safeAppData;
            subtitle.value = res.msg;
            if (!store.members || store.members.length === 0) {
                const membersRes = await axios.post('/getPatients', { pid: res.data.pid }) as any;

                if (membersRes.status !== 'success' || membersRes.data.length === 0) {
                    throw new Error('Failed to fetch list of members');
                }

                store.members = membersRes.data;
                store.setSelectedPatient(store.members.filter(x => x.pid == res.data.pid)[0]);
            }

            store.setBookedApp(safeAppData);
            setInfo(res.data.time);
        } else {
            toast({
                title: res.msg ?? 'An error occurred while fetching your appointment info. Please reload the page.',
                variant: 'destructive'
            });
            loader.value = false;
        }
    } catch {
        toast({
            title: 'An error occurred while fetching your appointment info. Please reload the page.',
            variant: 'destructive'
        });
        loader.value = false;
    }
}

function setInfo(time: string) {
    const date = convertServerDateToLocalDate(`${data.value.day} ${time}`);
    if (date.toLocaleString('en-US', { weekday: 'short' }) == new Date().toLocaleString('en-US', { weekday: 'short' })) {
        d1.value = 'Today'
    } else {
        d1.value = date.toLocaleString('en-US', { weekday: 'short' })
    }

    d2.value = date.toLocaleString('en-US', { month: 'short', day: 'numeric' })
    d3.value = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })
    data.value.time = bucketTime(d3.value.split(' ')[0]);

    if (data.value.status == 'WinRx') {

        let diff = diff_hours(date, new Date());

        if (diff < 24) {
            later.value = ` (${diff} hours later).`;
        }

    }

    setStatus(data.value.status);
    loader.value = false;
            
}

function diff_hours(dt2, dt1) {

    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
  
    return Math.abs(Math.round(diff));
  
}

function delAppointment() {
    if (!cancelLoader.value) { 
        cancelLoader.value = true;
        let values = {
            appID: route.query.appID || data.value.appID,
            pid: data.value.pid,
            doFrom: 'patient',
            save: true
        };
        publisher.publish('avee:click:appointment:cancel', {
            appointmentId: values.appID
        });

        axios.post('/delAppointment', values).then((res: any) => {
            if (res.status == 'success') {
                toast({
                    title: 'The appointment is cancelled.',
                });
                getAppt();
                
                publisher.publish('avee:appointment:cancel', {
                    appointmentId: values.appID
                });
            } else {
                toast({
                    title: 'Error in canceling appointment.',
                    variant: 'destructive'
                })
            }
        }).catch(() => {
            toast({
                title: 'Error in canceling appointment',
                variant: 'destructive'
            })
        }).finally(() => {
            cancelLoader.value = false;
        });
    }
}

function setStatus(status: String) {

    switch (status) {

        case 'Reserved':
            id.value = 5;
            title.value = 'Reserved';
            break;
        case 'WinRx':
        case 'Waiting':
            id.value = 1;
            title.value = 'Waiting';
            break;
        case 'No Show':
            id.value = 2;
            title.value = 'No Show';
            break;
        case 'Cancelled':
        case 'Canceled':
            id.value = 2;
            title.value = 'Cancelled';
            break;
        case 'Deleted':
            id.value = 2;
            title.value = 'Cancelled By Patient';
            break;
        case 'Billed':
        case 'Finished':
            id.value = 3;
            title.value = 'Successful Visit';
            break;
        case 'ReservationExpired':
            id.value = 6;
            title.value = 'Expired';
            break;
        case 'Changed':
            id.value = 4;
            title.value = 'Changed';
    }

}

function reschedule() {
    localStorage.setItem('reschedule', store.app.pid);
    
    publisher.publish('avee:click:appointment:reschedule', {
        appointmentId: route.query.appID || data.value.appID
    });
}

function bookNewAppointment() {
    publisher.publish('avee:click:appointment:book-new', {});
}

function pay() {
    if (!payLoader.value) {
        payLoader.value = true;
        let values = {
            appID: data.value.appID,
            successBackUrl: window.location.href.split('/manage_appointment')[0] + '/success',
            errorBackUrl: window.location.href.split('/manage_appointment')[0] + '/failed_pay'
        }
        axios.post('/checkout', values).then((res: any) => {
            if (res.status == 'success') {
                window.location.href = res.data.url
            } else {
                toast({
                    title: res.msg,
                    variant: 'destructive'
                });
            }
        }).catch(() => {
            toast({
                title: 'An error occurred while checking out. Please try again.',
                variant: 'destructive'
            });
        }).finally(() => {
            payLoader.value = false;
        });
    }
}
</script>

<template>
    <Loader2 v-if="loader" class="animate-spin w-10 h-10 mx-auto mt-10"/>
    <div v-else class="mt-32">
        <Hourglass v-if="id == 1 || id == 5" class="w-10 h-10 text-gray-900"/>
        <XCircle v-if="id == 2" class="w-10 h-10 text-red-600"/>
        <CheckCircle2 v-if="id == 3" class="w-10 h-10 text-teal-600"/>
        <AlertCircle v-if="id == 4" class="w-10 h-10 text-amber-600" />
        <CalendarX2 v-if="id == 6" class="w-10 h-10 text-red-600" />
        <h1 class="text-gray-900 text-4xl font-bold mt-4">{{ title }}</h1>
        <div class="mt-2 text-lg text-gray-700">{{ subtitle }}</div>
        <div class="my-12">
            <div class="flex items-center">
                <div class="border rounded-full border-gray-200 p-2.5 mr-3">
                    <Calendar class="w-5 h-5" />
                </div>
                <div>
                    <div class="text-gray-500 text-sm">Appointment</div>
                    <div class="mt-1 text-gray-900">
                        <span class="font-semibold">{{ d1 + ', ' + d2 + ', ' + d3 }}</span>
                        <span>{{ later }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="flex mt-5">
                <i class="isax isax-user p-1.5 mr-3"></i>
                <div>
                    <div class="text-gray-900 font-semibold">Patient</div>
                    <div class="mt-1 text-gray-500 text-sm">
                        {{ data.name?.split(', ').reverse().join(' ') }}
                    </div>
                </div>
            </div> -->
            <div class="flex items-center mt-5">
                <img v-if="data.doctor?.avatar" :src="data.doctor?.avatar" :alt="data.doctor?.name" class="mr-3 w-10 h-10 rounded-full">
                <div v-else class="border rounded-full border-gray-200 p-2.5 mr-3">
                    <User class="w-5 h-5" />
                </div>
                <div>
                    <div class="text-gray-500 text-sm">Doctor</div>
                    <div class="mt-1 text-gray-900 font-semibold">{{ data.doctor?.name }}</div>
                </div>
            </div>
        </div>
        <Button v-if="id == 3" size="lg" class="w-full font-bold mb-3">
            <i class="isax isax-add text-2xl mr-1"></i>
            Share your feedback
        </Button>
        <Button v-else-if="id == 2 || id == 3 || id == 6" size="lg" variant="ghost" as-child class="mb-3 w-full border border-gray-300 text-gray-700">
            <RouterLink :to="{ name: 'who', params: { domain: getParam(route) } }">Book a new appointment</RouterLink>
        </Button>
        <Button v-else-if="id == 4" size="lg" variant="ghost" as-child class="mb-3 w-full border border-gray-300 text-gray-700" @click="bookNewAppointment">
            <RouterLink :to="{ name: 'manage_appointment', query: {appID: data.new_appID}, params: { domain: getParam(route) } }">View Your New Appointment</RouterLink>
        </Button>
        <div v-else class="mb-3 flex gap-3">
            <Button v-if="id == 1" size="lg" variant="ghost" as-child class="w-full border border-gray-300 text-gray-700" @click="reschedule">
                <RouterLink :to="{ name: 'doctors', params: { domain: getParam(route) } }">Reschedule</RouterLink>
            </Button>
            <Button v-else-if="id == 5" size="lg" class="w-full" @click="pay" :disabled="payLoader">
                Pay
                <Loader2 v-if="payLoader" class="w-4 h-4 animate-spin ml-2"/>
            </Button>
            <AlertDialog>
                <AlertDialogTrigger as-child>
                    <Button 
                        size="lg" 
                        variant="ghost" 
                        class="w-full border border-gray-300 text-gray-700"
                        :disabled="cancelLoader"
                    >
                        Cancel
                        <Loader2 v-if="cancelLoader" class="w-4 h-4 animate-spin ml-2"/>
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Cancel Appointment</AlertDialogTitle>
                    <AlertDialogDescription>
                        Are you sure you want to cancel this appointment?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>  
                        No, keep appointment 
                    </AlertDialogCancel>
                    <AlertDialogAction 
                        @click="delAppointment"
                    >
                        Yes, cancel appointment
                    </AlertDialogAction>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
        <Button v-if="id == 3" size="lg" variant="ghost" class="w-full border border-gray-300 text-gray-700 mb-3">Get payment receipt</Button>
        <div class="font-semibold text-gray-900">
            Need help? <SupportChat />
        </div>
    </div>
</template>
