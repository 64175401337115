<script setup lang="ts">
import Address from '@/components/address/Address.vue';
import { schema as addressSchema } from '@/components/address/schema';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/toast/use-toast';
import { clearLocalStorage, getParam, updateAxiosAuthHeader } from '@/lib/utils';
import { store } from '@/store/store';
import { toTypedSchema } from '@vee-validate/zod';
import axios from 'axios';
import { Loader2 } from 'lucide-vue-next';
import { InferOutput, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { FormError, useFormError } from '@/components/form-error'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

const { toast } = useToast();
const route = useRoute();
const router = useRouter();
const loader = ref(false);

const formSchema = toTypedSchema(
    addressSchema.refine(({ country, postal_code }) => {
        if (country === 'ca') {
            return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal_code);
        } else if (country === 'us') {
            return /^\d{5}(-\d{4})?$/.test(postal_code);
        }
        return true;
    }, { message: "Invalid postal/ZIP code format", path: ['postal_code'] })
);

const { handleSubmit } = useForm({
    validationSchema: formSchema,
    initialValues: {
        country: 'ca',
        province: 'BC',
        address: '',
        city: '',
        postal_code: ''
    }
});

const { formError, setFormError, clearFormError } = useFormError()

const onSubmit = handleSubmit((formValues) => {
    if (loader.value) {
        return;
    }

    loader.value = true;
    storeNewPatient(formValues);

    axios.post('/userRegister', store.newPatient).then((res: any) => {
        
        if (res.status === 'success') {
            const params = {
                pid: res.data.pid
            }

            axios.post('/refreshToken', params).then((res2: any) => {

                if (res2.status === 'success') {
                    const newToken = res2.data.token;
                    localStorage.setItem('token', newToken);
                    updateAxiosAuthHeader(newToken);

                    store.members = res2.data.people;
                    store.setSelectedPatient(res.data);
                    store.login();
                    clearLocalStorage();

                    if (!!route.query['redirect_to'] && typeof route.query['redirect_to'] === 'string') {
                        loader.value = false;
                        router.push(route.query['redirect_to']);
                        return;
                    }

                    setTimeout(function () {
                        loader.value = false;
                        router.push({ name: 'who', params: { domain: getParam(route) } });
                    }, 300);
                } else {
                    toast({
                        title: res2.msg ?? DEFAULT_ERROR_MESSAGE,
                        variant: 'destructive'
                    });
                    loader.value = false;
                }
            });
        } else {
            toast({
                variant: 'destructive',
                title: res.msg ?? DEFAULT_ERROR_MESSAGE
            });
            loader.value = false;
        }

    });
}, (errors) => setFormError(errors.toString()));

function storeNewPatient(formValues: InferOutput<typeof formSchema>) {
    store.newPatient.province = formValues.province;
    store.newPatient.address = formValues.address;
    store.newPatient.city = formValues.city;
    store.newPatient.postal_code = formValues.postal_code;

    const patientPhone = localStorage.getItem('phone');
    if (patientPhone) {
        store.newPatient.phone = patientPhone;
    }
}

onMounted(() => {
    // if the user reaches this page, we must make sure that the previous steps are complete
    // otherwise, redirect to the previous step.
    // this implementation isn't ideal because we're assuming that certain fields
    // must be filled in and if someone wants to add a field in the future, they
    // must update this code too. however, it's a quick and dirty solution.
    if (route.name !== 'address') {
        return;
    }

    const {
        name,
        family, 
        gender,
        birthday
    } = store.newPatient

    const isEmpty = (item: string) => !item || item.length === 0;

    const previousStepIsIncomplete = [name, family, gender, birthday].some(isEmpty);
    if (previousStepIsIncomplete) {
        router.push({ name: 'login', params: { domain: getParam(route) } });
    }
})
</script>

<template>
    <h2 class="title">Where do you live?</h2>
    <div class="mt-1 text-gray-600">We need your address to create your medical record.</div>
    <form @submit="onSubmit" @input="clearFormError()" class="grid gap-7 mt-10">
        <Address />
        <Button type="submit" size="lg" class="font-bold w-full mt-4 text-lg" :disabled="loader">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Continue
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
        <FormError v-if="formError" show-back-to-top />
    </form>
</template>
