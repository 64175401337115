<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Header from './components/shared/Header.vue'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { useIntercom } from './utils/intercom'
import { theme } from '@/store/features'

const windowWidth = ref(0)


useIntercom();

onMounted(() => {
    windowWidth.value = window.innerWidth
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    })
})
</script>

<template>
  <div class="wrapper first-page bg-gray-100" :class="{
    'theme-pocket-pills': theme === 'pocket-pills',
    'hide-background': $route.name == 'delegate'
  }">
    <div class="container min-h-screen !pb-16"
      :class="{ 'max-w-[580px]': $route.name == 'home', 'flex justify-center items-center': $route.name == 'success' || $route.name == 'failed_pay' || $route.name == 'delegate' }">
      <div class="flex w-full">
        <div class="grow relative w-full">
          <Header
            v-if="$route.name != 'delegate'"
            v-show="$route.name != 'home' && $route.name != 'NotFound' && $route.name != 'success' && $route.name != 'failed_pay' && $route.name != 'manage_appointment' && $route.name != 'pharmacy'"
             />
          <div class="content-wrapper flex justify-center">
            <div class="relative max-w-md w-full">
              <router-view v-slot="{ Component }">
                <keep-alive exclude="Verify,Details,Pay,Success,FailedPay,Delegate">
                  <component :is="Component" />
                </keep-alive>
              </router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toaster />
</template>

<style>
.wrapper {
  position: relative;
  min-height: 100vh;
}

.wrapper::before {
  content: '';
  position: absolute;
  background-image: url('/bg.jpg');
  background-size: 100% 300px;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 300px;
}

.container {
  position: relative;
  padding: 1.25rem;
}

@media (min-width: 640px) {
  .wrapper::before {
    height: 100%;
  }
}
</style>
