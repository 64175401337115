<script lang="ts">
  export default {
    name: 'Pay'
  }
</script>

<script setup lang="ts">
import DateOfBirth from '@/components/date-of-birth/DateOfBirth.vue'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { clearLocalStorage, delay, getParam } from '@/lib/utils'
import { store } from '@/store/store'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { show as showIntercom } from '@intercom/messenger-js-sdk'
import { toTypedSchema } from '@vee-validate/zod'
import { watchDebounced } from '@vueuse/core'
import axios from 'axios'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as z from 'zod'
import { schema as dateOfBirthSchema } from '@/components/date-of-birth/schema'

const { toast } = useToast()

const route = useRoute();
const router = useRouter();

const disableEditingPhn = ref(false);
const paymentType = ref<'health-card' | 'pay' | 'insurance' | 'invalid-phn' | 'none'>('none')
const loaderPage = ref(true);

const formSchema = toTypedSchema(z.object({
    phn: z.string().length(10, 'Health Card Number must be 10 digits long'),
}).merge(dateOfBirthSchema));

const { handleSubmit, setValues, setFieldValue } = useForm({
    validationSchema: formSchema,
})

const phn = ref('')
const inlineErrorMessage = ref<Nullable<'duplicate' | 'invalid' | 'oscar-timeout'>>(null)

function choosePaymentType(kind: typeof paymentType.value) {
    if (paymentType.value == 'insurance') {
        router.push({ name: 'details', params: { domain: getParam(route) } });
        return;
    }

    paymentType.value = kind;
}

function useCheckHIN() {
    const loaderCheckHIN = ref(false);

    async function checkHIN() {
        if (loaderCheckHIN.value) return;

        loaderCheckHIN.value = true;

        try {
            const hinResponse = await axios.post('/checkHin', { pid: store.appointment.pid }) as any;

            if (hinResponse.status == 'success') {
                const { data } = hinResponse;
                const { status, action } = data;

                if (status === 'valid') {
                    paymentType.value = 'insurance';
                } else if (status === 'invalid' && action === 'allow') {
                    paymentType.value = 'health-card';
                    setValues({
                        phn: data.hin,
                        year: data.birthday.slice(0, 4),
                        month: data.birthday.slice(5, 7),
                        day: data.birthday.slice(8, 10)
                    }, false);
                } else if (status === 'invalid' && action === 'notAllow') {
                    paymentType.value = 'invalid-phn';
                    setFieldValue('phn', data.hin);
                    disableEditingPhn.value = true;
                } else {
                    paymentType.value = 'none'
                }
            } else {
                throw new Error(hinResponse.msg);
            }
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: error.message ?? 'An error occurred while checking your health card.',
                    variant: 'destructive'
                })
            }
        } finally {
            loaderCheckHIN.value = false;
        }
    }

    return {
        loader: loaderCheckHIN,
        checkHIN
    }
}

function useSetAppointment() {
    const loaderSetAppointment = ref(false);

    async function setAppointment(kind: 'bc' | 'nobc', patientInfo?: { phn: string, birthday: string }) {
        if (loaderSetAppointment.value) return;

        store.appointment.phnType = kind;

        if (patientInfo) {
            store.appointment.hin = patientInfo?.phn;
            store.appointment.birthday = patientInfo?.birthday;
        } else {
            store.appointment.hin = '';
            store.appointment.birthday = '';
        }
        
        loaderSetAppointment.value = true;
        inlineErrorMessage.value = null;

        let code: number | null = null;

        try {
            const setAppointmentResponse = await axios.post('/setNewAppointment', store.appointment) as any;
            if (!setAppointmentResponse.code) {
                throw new Error('An error occurred while setting your appointment. Please try again.');
            }

            code = parseInt(setAppointmentResponse.code, 10);

            if (setAppointmentResponse.status !== 'success') {
                throw new Error(setAppointmentResponse.msg);
            }

            clearLocalStorage();

            const appointmentIsPrivate = code === 6
            const customerShouldPay = (kind == 'bc' && appointmentIsPrivate) || kind === 'nobc';

            if (customerShouldPay) {
                const checkoutParams = {
                    appID: setAppointmentResponse.data.appID,
                    successBackUrl: window.location.href.split('/pay')[0] + '/success',
                    errorBackUrl: window.location.href.split('/pay')[0] + '/failed_pay'
                }
                const checkoutResponse = await axios.post('/checkout', checkoutParams) as any;

                if (checkoutResponse.status !== 'success') {
                    throw new Error(checkoutResponse.msg);
                }

                if (!checkoutResponse.data?.url) {
                    throw new Error('Failed to connect to payment gateway. Please try again.');
                }
                
                window.location.href = checkoutResponse.data.url
            } else {
                await delay(300)
                router.push({
                    name: 'success', params: { domain: getParam(route) }, query: {
                        appID: setAppointmentResponse.data.appID
                    }
                })
            }
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: error.message ?? 'An error occurred while setting your appointment.',
                    variant: 'destructive'
                })

                const failedBacuseHINIsDuplicate = code === 120;
                if (failedBacuseHINIsDuplicate) {
                    inlineErrorMessage.value = 'duplicate';
                }

                const failedBecauseHINIsInvalid = code === 102;
                if (failedBecauseHINIsInvalid) {
                    inlineErrorMessage.value = 'invalid';
                }

                const failedBacauseOfOscarTimeout = code === 130;
                if (failedBacauseOfOscarTimeout) {
                    inlineErrorMessage.value = 'oscar-timeout';
                }

                if (!inlineErrorMessage.value) {
                    router.push({ name: 'times', params: { domain: getParam(route) } });
                }
            }
        } finally {
            loaderSetAppointment.value = false;
        }
    }

    return {
        loader: loaderSetAppointment,
        setAppointment
    }
}

const { loader: loaderCheckHIN, checkHIN } = useCheckHIN();
const { loader: loaderSetAppointment, setAppointment } = useSetAppointment();

const onSubmit = handleSubmit((formValues) => {
    if (!loaderSetAppointment.value) {
        const birthdayStr = formValues.year + '-' + formValues.month + '-' + formValues.day;
        setAppointment('bc', {
            phn: formValues.phn,
            birthday: birthdayStr
        });
    }
});

function goNext() {
    if (!loaderSetAppointment.value) {
        // we should send empty hin and birthday, otherwise the server will reject the request
        setAppointment('nobc');
    }
}

function openChat() {
    showIntercom();
}

// compute value of loaderPage based on other loaders
watchDebounced([loaderCheckHIN], () => {
    loaderPage.value = loaderCheckHIN.value || loaderSetAppointment.value
}, { debounce: 100 });

onMounted(async () => {
    const localInfo = JSON.parse(localStorage.getItem('appointment') ?? '{}');
    if (Object.keys(localInfo).length === 0) {
        router.push({ name: 'who', params: { domain: getParam(route) } });
        return;
    }

    if (store.clinic.id) {
        store.appointment.cid = store.clinic.id;
        store.appointment.clinicID = store.clinic.id;
    }
    
    if (store.appointment.pid) {
        loaderPage.value = true;
        await checkHIN();

        if (paymentType.value === 'insurance') {
            setAppointment('bc'); 
        }
    } else {
        router.push({ name: 'who', params: { domain: getParam(route) } });
    }
});
</script>

<template>
    <div v-if="loaderPage" class="mt-16">
        <Loader2 class="w-10 h-10 mx-auto animate-spin " />
        <div class="mt-3 text-gray-500 text-center">Verifying Your Health Card...</div>
    </div>
    <div v-else-if="paymentType === 'invalid-phn'">
        <h2 class="title">Health Card Verification Failed!</h2>
        <div class="mt-1 text-gray-600">We’re sorry, but your health card was not accepted by MSP.
            Please contact MSP for more details.
            <p class="mt-3">To continue your visit, kindly proceed with the $49 payment.</p>
            <div class="mt-10">
                <Label for="showHIN">Health Card Number (non-editable)</Label>
                <Input id="showHIN" disabled :placeholder="phn" class="mt-1.5" />
            </div>
            <Button size="lg" class="font-bold w-full text-lg mt-10" @click="goNext" :disabled="loaderSetAppointment">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Pay
                <span class="ml-1 font-normal">(${{ parseFloat(store.selectedDoctor.price.price) + ' ' +
                    store.selectedDoctor.price.currency }})</span>
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
        </div>
    </div>
    <div v-else>
        <h2 class="title">Do you have Health Card?</h2>
        <div class="mt-1 text-gray-600">If you have a BC Personal Health Number, our service is free.</div>
        <div class="mt-10 grid gap-3">
            <Card class="p-3 cursor-pointer"
                :class="{'border-2 border-primary bg-card-active': paymentType === 'health-card'}"
                @click="choosePaymentType('health-card')">
                <div class="font-medium text-gray-950">Yes. I have a BC Health Card.</div>
                <div class="mt-1 text-sm text-gray-400">Free</div>
            </Card>
            <Card class="p-3 cursor-pointer"
                :class="{'border-2 border-primary bg-card-active': paymentType === 'pay', 'cursor-not-allowed opacity-60': paymentType === 'insurance'}"
                @click="choosePaymentType('pay')">
                <div class="font-medium text-gray-950">No, I will pay for the visit</div>
                <div class="mt-1 text-sm text-gray-400">${{ parseFloat(store.selectedDoctor.price.price) + ' ' +
                    store.selectedDoctor.price.currency }}</div>
            </Card>
        </div>
        <form @submit="onSubmit" v-show="paymentType === 'health-card'" class="mt-10 grid gap-7">
            <h3 class="text-2xl font-bold text-gray-900">Enter your Health Card Info</h3>
            <FormField v-slot="{ componentField }" name="phn" v-model:model-value="phn">
                <FormItem v-auto-animate>
                    <FormControl>
                        <div class="grid gap-1.5">
                            <Label for="phn">Health Card Number (MSP)</Label>
                            <Input id="phn" type="text" v-bind="componentField" :disabled="disableEditingPhn" />
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
            <div class="grid gap-1.5">
                <Label>Date of birth</Label>
                <DateOfBirth :is-disabled="disableEditingPhn" />
            </div>
            <Button type="submit" size="lg" class="font-bold w-full mt-4 text-lg" :disabled="loaderSetAppointment">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Done
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
            <FormError v-if="inlineErrorMessage === 'invalid'" />
            <FormError v-else-if="inlineErrorMessage === 'duplicate'" class="cursor-pointer" @click="openChat">
                This Health Card Number is already registered in our system. If you
                believe this is your correct number, <span class="font-semibold">click here to chat with support</span>.
            </FormError>
            <FormError v-else-if="inlineErrorMessage === 'oscar-timeout'">
                Creating your appointment failed because of a technical issue. Please try again.
            </FormError>
        </form>
        <div v-if="paymentType === 'pay'" class="mt-10 grid gap-7">
            <Button size="lg" class="font-bold w-full text-lg" @click="goNext" :disabled="loaderSetAppointment">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Pay
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
        </div>
    </div>
</template>
