type AveeEventTypes = {
    // after an appointment is successfully booked
    "avee:appointment:success": {
        appointmentId: string;
    };
    // after failure during the booking process of an appointment
    "avee:appointment:failure": {
        error: 'payment-failure' | 'unknown';
    };
    // after an appointment is successfully cancelled (by patient)
    "avee:appointment:cancel": {
        appointmentId: string;
    };
    // after clicking the cancel button (by patient)
    "avee:click:appointment:cancel": {
        appointmentId: string;
    };
    // after clicking the reschedule button
    "avee:click:appointment:reschedule": {
        appointmentId: string;
    };
    // after clicking the book new appointment button
    "avee:click:appointment:book-new": {};
    // if authentication fails
    "avee:delegation:failure": {
        error: 'invalid-token' | 'unknown';
    };
};

interface AveeEvent<T extends keyof AveeEventTypes> {
    name: T;
    payload: AveeEventTypes[T];
}

export function usePublisher() {
    function publish<T extends keyof AveeEventTypes>(
        event: T,
        payload: AveeEventTypes[T]
    ) {
        const eventToPublish: AveeEvent<T> = {
            name: event,
            payload: payload
        };
        try {
            window.postMessage(eventToPublish, "*");
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error publishing event:', error);
        }
    }

    return {
        publish,
    };
}
