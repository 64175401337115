<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { useRouter, useRoute } from 'vue-router'
import { store } from '@/store/store';
import axios from 'axios'
import { useToast } from '@/components/ui/toast/use-toast'
import { ref } from 'vue'
import { delay, getParam } from '@/lib/utils'

const loader = ref(false)

const { toast } = useToast()

const route = useRoute();
const router = useRouter();

const formSchema = toTypedSchema(z.object({
    phone: z.coerce.string().length(10, {message: 'This phone number is invalid.'})
}))

const { handleSubmit } = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit( async (formFields) => {
    if (!loader.value) {
        loader.value = true;

        try {
            const res = await axios.post('/checkPhone', { phone: formFields.phone }) as any;
            if (res.status !== 'success') {
                throw new Error('This phone number is invalid.');
            }
            
            store.phone = formFields.phone;
            store.uniq = res.data.uniq;

            const redirectTo = route.query['redirect_to'];
            const query = !!redirectTo ? { query: { redirect_to: redirectTo } } : {};

            await delay(300);
            router.push({ name: 'verify', params: { domain: getParam(route) }, ...query });
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: error.message,
                    variant: 'destructive'
                });
            }
        } finally {
            loader.value = false;
        }
    }
});
</script>

<template>
    <h2 class="title">What is your number?</h2>
    <div class="mt-1 text-gray-600">Enter your number to continue.</div>
    <form @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="phone">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="relative mt-7">
                        <Input id="phone" type="number" placeholder="1234567890" class="pl-14" v-bind="componentField"/>
                        <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 w-12 border-r border-gray-100">
                        +1
                        </span>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <Button type="submit" size="lg" class="font-bold w-full mt-7 text-lg" :disabled="loader">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Continue
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
    </form>
    <div class="mt-2 font-medium text-sm text-gray-500">
        I wish to receive text messages (e.g. appointment details, reminders) at the number provided. Standard message rates apply. 
        <a href="https://www.avee.health/privacy-policy" target="_blank" rel="noopener noreferrer" class="text-gray-900 underline">Privacy Policy</a>
    </div>
</template>
